import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  signal,
} from '@angular/core';

@Directive({
  selector: '[sdTooltip]',
  standalone: true,
})
export class TooltipDirective {
  @Input('sdTooltip') tooltipText = '';
  @Input() sdTooltipDirection: 'top' | 'right' = 'top';
  private tooltipElement: HTMLElement | null = null;
  private readonly TABLET_LANDSCAPE_WIDTH = 905;

  public readonly isMobile = signal(
    window.screen.width < this.TABLET_LANDSCAPE_WIDTH
  );

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    if (!this.tooltipElement && !this.isMobile()) {
      this.showTooltip();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hideTooltip();
  }

  private showTooltip() {
    this.tooltipElement = this.renderer.createElement('span') as HTMLElement;
    if (this.tooltipElement) {
      this.tooltipElement.innerText = this.tooltipText;

      const classes = [
        'bg-neutral-700',
        'text-shades-white',
        'typo-caption',
        'font-semibold',
        'py-1',
        'px-2',
        'rounded',
        'max-w-70',
      ];

      classes.forEach((className) => {
        this.renderer.addClass(this.tooltipElement, className);
      });

      this.renderer.setStyle(this.tooltipElement, 'z-index', '1000');
      this.renderer.appendChild(document.body, this.tooltipElement);
    }
    const hostPos = (
      this.el.nativeElement as HTMLElement
    ).getBoundingClientRect();
    const tooltipPos = this.tooltipElement.getBoundingClientRect();

    // Positioning the tooltip
    let top: number;
    let left: number;

    switch (this.sdTooltipDirection) {
      case 'right':
        top =
          hostPos.top +
          (hostPos.height - tooltipPos.height) / 2 +
          window.scrollY;
        left = hostPos.right + 8 + window.scrollX;
        break;
      case 'top':
      default:
        top = hostPos.top - tooltipPos.height - 8 + window.scrollY;
        left =
          hostPos.left +
          (hostPos.width - tooltipPos.width) / 2 +
          window.scrollX;
        break;
    }

    this.renderer.addClass(this.tooltipElement, 'absolute');
    this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
  }

  private hideTooltip() {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }
}
