import { Directive, computed, input } from '@angular/core';
import { cva, type VariantProps } from 'class-variance-authority';

import * as fromHelpers from '../helpers';

export enum AvatarColorVariant {
  // TODO: Implement
  // primary = 'primary',
  light = 'light',
  dark = 'dark',
}

export enum AvatarSizeVariant {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export const avatarVariants = cva(
  fromHelpers.cn(
    'flex items-center justify-center rounded-full hover:cursor-pointer'
  ),
  {
    variants: {
      color: {
        [`${AvatarColorVariant.light}`]:
          'bg-primary-100 hover:bg-primary-200 text-primary-500 font-medium',
        [`${AvatarColorVariant.dark}`]:
          'bg-transparency-white-32 hover:bg-transparency-white-16 text-shades-white font-medium',
      },
      size: {
        [`${AvatarSizeVariant.xs}`]:
          'min-w-6 w-6 h-6 typo-footer [&>svg]:size-4',
        [`${AvatarSizeVariant.sm}`]:
          'min-w-8 w-8 h-8 typo-caption [&>svg]:size-4',
        [`${AvatarSizeVariant.md}`]:
          'min-w-10 w-10 h-10 typo-p3 [&>svg]:size-5',
        [`${AvatarSizeVariant.lg}`]:
          'min-w-12 w-12 h-12 typo-p3 [&>svg]:size-5',
        [`${AvatarSizeVariant.xl}`]:
          'min-w-14 w-14 h-14 typo-p2 [&>svg]:size-6',
      },
    },
    defaultVariants: {
      color: AvatarColorVariant.dark,
      size: AvatarSizeVariant.md,
    },
  }
);
export type AvatarVariants = VariantProps<typeof avatarVariants>;

@Directive({
  selector: '[sdAvatar]',
  standalone: true,
  host: {
    '[class]': 'className()',
  },
})
export class AvatarDirective {
  readonly color = input<AvatarVariants['color']>(AvatarColorVariant.dark);
  readonly size = input<AvatarVariants['size']>(AvatarSizeVariant.md);

  protected readonly className = computed(() =>
    fromHelpers.cn(
      avatarVariants({
        color: this.color(),
        size: this.size(),
      })
    )
  );
}
