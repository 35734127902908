import { Directive, input } from '@angular/core';

import { ArrowIconsType } from './arrows';
import { BaseIconDirective } from './base-icon.directive';

@Directive({
  selector: 'svg[sdUseArrowIcon]',
  standalone: true,
})
export class UseArrowIconDirective extends BaseIconDirective {
  public readonly sdUseArrowIcon = input.required<ArrowIconsType>();
  readonly BASE_PATH = '/assets/arrows.sprites.svg';

  public get icon() {
    return this.sdUseArrowIcon();
  }
}
