import { NgClass } from '@angular/common';
import { Component, forwardRef, input, output } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import * as fromHelpers from '../helpers';

@Component({
  selector: 'sd-input',
  imports: [NgClass, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  styles: [],
  template: `
    <div class="flex flex-col gap-2 group">
      <label
        [for]="id"
        class="typo-caption font-semibold leading-4 h-5 self-start px-1 py-0.5"
        [ngClass]="{
          'text-neutral-400': isDisabled,
          'text-neutral-700': !isDisabled,
          'group-focus-within:text-primary-400': !isDisabled && !showError()
        }"
      >
        {{ label() }}
      </label>
      <input
        [id]="id"
        type="text"
        [class]="
          cn(
            'border',
            'focus:outline-none',
            'rounded-lg',
            'px-4 py-2 h-12',
            'typo-p3',
            {
              'text-neutral-400 hover:border-neutral-200': isDisabled,
              'hover:border-primary-300': !isDisabled,
              'border-neutral-200 focus:border-primary-500 caret-primary-700':
                !showError(),
              'border-error-500 hover:border-error-500 focus:border-error-500 caret-error-500':
                showError()
            }
          )
        "
        [placeholder]="placeholder()"
        [disabled]="isDisabled"
        [value]="value"
        (input)="onInput($event)"
        [attr.maxlength]="limit() > 0 ? limit() : null"
      />
      @if (showError() && errorMessage()) {
      <div class="typo-caption text-error-500">
        {{ errorMessage() }}
      </div>
      }
    </div>
  `,
})
export class InputComponent implements ControlValueAccessor {
  public readonly label = input<string>('Default Label');
  public readonly placeholder = input<string>('Default Placeholder');
  public readonly limit = input<number>(0);
  public readonly errorMessage = input<string | undefined>();
  public readonly showError = input<boolean>(false);
  public readonly cn = fromHelpers.cn;

  public readonly valueChanged = output<string>();

  value = '';
  isDisabled = false;
  id = Math.random();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  onChange = (value: string) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  // Method called when the value of the parent form changes
  writeValue(value: string): void {
    this.value = value;
  }

  // Stores a function to be called when the input value changes
  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  // Stores a function to be called when the input is touched
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Updates the disabled status of the field
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // Manages the input event and notifies the parent of value changes
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.value =
      this.limit() > 0
        ? inputElement.value.slice(0, this.limit())
        : inputElement.value;
    this.onChange(this.value);
    this.valueChanged.emit(this.value);
  }
}
