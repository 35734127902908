export enum SocialIconsEnum {
  'facebook-square' = 'facebook-square',
  'twitter-square' = 'twitter-square',
  'indeed-square' = 'indeed-square',
  'tiktok-square' = 'tiktok-square',
  'youtube-square' = 'youtube-square',
  'instagram-square' = 'instagram-square',
  'google-square' = 'google-square',
  'microsoft-square' = 'microsoft-square',
  'apple-square' = 'apple-square',
  'facebook-circle' = 'facebook-circle',
  'twitter-circle' = 'twitter-circle',
  'indeed-circle' = 'indeed-circle',
  'tiktok-circle' = 'tiktok-circle',
  'youtube-circle' = 'youtube-circle',
  'instagram-circle' = 'instagram-circle',
  'google-circle' = 'google-circle',
  'microsoft-circle' = 'microsoft-circle',
  'apple-circle' = 'apple-circle',
  'facebook-no-bg' = 'facebook-no-bg',
  'twitter-no-bg' = 'twitter-no-bg',
  'indeed-no-bg' = 'indeed-no-bg',
  'tiktok-no-bg' = 'tiktok-no-bg',
  'youtube-no-bg' = 'youtube-no-bg',
  'instagram-no-bg' = 'instagram-no-bg',
  'google-no-bg' = 'google-no-bg',
  'microsoft-no-bg' = 'microsoft-no-bg',
  'apple-no-bg' = 'apple-no-bg',
}

export type SocialIconsType = `${SocialIconsEnum}`;
export const SocialIconsList = Object.values(SocialIconsEnum);
