import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

export interface ItemNavigation {
  id: number;
  selected: boolean;
  title: string;
  date: DateTime;
}

export type CategoryName = string;
export type CategoryNameTranslation = string;

@Injectable()
export class ItemsNavigationHelper {
  private readonly translate = inject(TranslateService);

  public getCategories(
    items: ItemNavigation[]
  ): Record<string, ItemNavigation[]> {
    const now = new Date();

    return items.reduce(
      (categories, item) => {
        const diffDays = this.calculateDiffDays(now, item.date.toJSDate());
        const categoryName = this.getCategoryByDiffDays(diffDays);

        if (!categories[categoryName]) {
          categories[categoryName] = [];
        }
        categories[categoryName].push(item);

        return categories;
      },
      { today: [] } as Record<string, ItemNavigation[]>
    );
  }

  public getCategoriesKeys(
    categories: Record<string, ItemNavigation[]>
  ): CategoryName[] {
    return Object.keys(categories).filter(
      (categoryName) => categories[categoryName].length > 0
    );
  }

  public getCategoryNameTranslation(
    category: CategoryName
  ): CategoryNameTranslation {
    const translationKeys = {
      today: 'NAVIGATION_ITEMS.TODAY',
      yesterday: 'NAVIGATION_ITEMS.YESTERDAY',
      'days ago': 'NAVIGATION_ITEMS.X_DAYS_AGO',
      'weeks ago': 'NAVIGATION_ITEMS.X_WEEKS_AGO',
      'months ago': 'NAVIGATION_ITEMS.X_MONTHS_AGO',
      'years ago': 'NAVIGATION_ITEMS.X_YEARS_AGO',
    };

    const [key, translationKey] =
      Object.entries(translationKeys).find(([k]) => category.includes(k)) ?? [];

    if (key && translationKey) {
      const value = category.replace(key, '').trim();
      return this.translate.instant(translationKey, {
        [key.split(' ')[0]]: value,
      }) as string;
    }

    return category;
  }

  private calculateDiffDays(now: Date, itemDate: Date): number {
    const date = new Date(itemDate);
    const diffTime = now.getTime() - date.getTime();
    const diffDays = Math.floor(diffTime / (1000 * 3600 * 24));

    // If the difference is less than 24 hours, check if itemDate is after midnight
    if (diffDays === 0 && now.getDate() !== date.getDate()) {
      return 1; // “yesterday
    }

    return diffDays;
  }

  private getCategoryByDiffDays(diffDays: number): CategoryName {
    if (diffDays <= 0) return 'today';
    if (diffDays === 1) return 'yesterday';
    if (diffDays < 7) return `${diffDays} days ago`;
    if (diffDays < 30)
      return `${Math.floor(diffDays / 7)} week${
        Math.floor(diffDays / 7) > 1 ? 's' : ''
      } ago`;
    if (diffDays < 365)
      return `${Math.floor(diffDays / 30)} month${
        Math.floor(diffDays / 30) > 1 ? 's' : ''
      } ago`;
    return `${Math.floor(diffDays / 365)} year${
      Math.floor(diffDays / 365) > 1 ? 's' : ''
    } ago`;
  }
}
