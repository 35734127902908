import { Component, effect, input, output, signal } from '@angular/core';
import { UseUIBasicIconDirective } from '../atoms';
import {
  ButtonDirective,
  ButtonVariants,
  IconButtonDirective,
} from '../molecules';

export interface ModalAction {
  label: string;
  action: string;
  type: 'save' | 'delete' | 'cancel';
  disabled: boolean;
}

export interface Modals {
  title: string;
  showCloseButton?: boolean;
  actions: ModalAction[];
  backdropDismiss?: boolean;
  isFullscreen?: boolean;
}

@Component({
  selector: 'sd-modal',
  imports: [ButtonDirective, IconButtonDirective, UseUIBasicIconDirective],
  template: `
    <div
      class="transition-opacity duration-500 ease-in-out"
      [class.modal-backdrop]="isBackdropVisible()"
      [class.opacity-0]="!isBackdropVisible()"
      [class.opacity-100]="isBackdropVisible()"
      (click)="modalOptions().backdropDismiss && onTriggerAction('cancel')"
    >
      <div
        class="
          flex flex-col
          shadow-lg
          bg-shades-white
          w-[calc(100%-2rem)] tablet-landscape:w-min
          [&.fullscreen]:w-svw [&.fullscreen]:h-svh
          [&:not(.fullscreen)]:max-h-[calc(100svh-5rem)]
          absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
          overflow-hidden
        "
        [class.rounded-3xl]="!modalOptions().isFullscreen"
        [class.fullscreen]="modalOptions().isFullscreen"
        [class.visible]="isModalVisible()"
        [class.invisible]="!isModalVisible()"
        (click)="modalOptions().backdropDismiss && $event.stopPropagation()"
      >
        <div
          class="sticky top-0 z-10 flex justify-between items-center typo-p1 font-semibold text-neutral-900 px-6 py-4 border-b border-neutral-200 leading-6 h-16 bg-shades-white"
          [class.typo-p2]="modalOptions().isFullscreen"
        >
          {{ modalOptions().title }}
          @if(modalOptions().showCloseButton) {
          <button
            sdIconButton
            variant="icon"
            color="neutral"
            size="sm"
            (click)="onTriggerAction('cancel')"
          >
            <svg sdUseUIBasicIcon="close"></svg>
          </button>
          }
        </div>
        <div class="overflow-y-auto flex-1">
          <div
            class="typo-p2 text-neutral-700 p-6 leading-6"
            [class.flex-grow]="modalOptions().isFullscreen"
          >
            <ng-content></ng-content>
          </div>
        </div>
        <div
          class="sticky bottom-0 z-10 flex justify-end gap-4 pb-4 px-4 border-neutral-200 bg-shades-white"
          [class.border-t]="modalOptions().isFullscreen"
          [class.pt-4]="modalOptions().isFullscreen"
        >
          @for (action of modalOptions().actions; track action.label) {
          <button
            sdButton
            [variant]="this.getButtonVariant(action)"
            [color]="this.getButtonColor(action)"
            [size]="isMobile() ? 'md' : 'sm'"
            [disabled]="action.disabled"
            (click)="onTriggerAction(action.action)"
            [class.flex-grow]="modalOptions().isFullscreen"
          >
            {{ action.label }}
          </button>
          }
        </div>
      </div>
    </div>
  `,
  styles: [``],
})
export class ModalComponent {
  public readonly modalOptions = input.required<Modals>();
  public readonly isModalVisible = input.required<boolean>();
  public readonly triggerAction = output<string>();

  public isBackdropVisible = signal(false);

  private readonly TABLET_LANDSCAPE_WIDTH = 905;
  public readonly isMobile = signal(
    window.innerWidth <= this.TABLET_LANDSCAPE_WIDTH
  );

  constructor() {
    effect(
      () => {
        this.isBackdropVisible.set(this.isModalVisible());
      },
      { allowSignalWrites: true }
    );
  }

  public getButtonVariant(action: ModalAction): ButtonVariants['variant'] {
    switch (action.type) {
      case 'save':
      case 'delete':
        return 'filled';
      case 'cancel':
        return 'outline';
    }
  }

  public getButtonColor(action: ModalAction): ButtonVariants['color'] {
    switch (action.type) {
      case 'save':
        return 'primary';
      case 'delete':
        return 'error';
      case 'cancel':
        return 'neutral';
    }
  }

  public onTriggerAction(action: string) {
    this.triggerAction.emit(action);
  }
}
