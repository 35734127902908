import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Renderer2,
  inject,
  input,
} from '@angular/core';
import { IllustrationType } from './illustration';

@Directive({
  selector: 'img[sdUseIllustration]',
  standalone: true,
})
export class UseIllustrationDirective implements AfterViewInit {
  readonly elementRef = inject<ElementRef<SVGElement>>(ElementRef);
  readonly renderer = inject(Renderer2);
  readonly document = inject<Document>(DOCUMENT);

  readonly sdUseIllustration = input.required<IllustrationType>();

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.setAttribute(
      'src',
      `/assets/illustrations/${this.sdUseIllustration()}.png`
    );
  }
}
