import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { UseAiIconDirective } from '../atoms';

@Component({
  selector: 'sd-message',
  imports: [NgClass, UseAiIconDirective, MarkdownModule],
  styles: [
    `
      /**
    * ==============================================
    * Dot Flashing
    * ==============================================
    */
      .dot-flashing {
        position: relative;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 0.1875rem;
        background-color: theme('colors.neutral.200');
        color: theme('colors.neutral.200');
        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;
      }
      .dot-flashing::before,
      .dot-flashing::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
      }
      .dot-flashing::before {
        left: -0.5rem;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 0.1875rem;
        background-color: theme('colors.neutral.200');
        color: theme('colors.neutral.200');
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
      }
      .dot-flashing::after {
        left: 0.5rem;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 0.1875rem;
        background-color: theme('colors.neutral.200');
        color: theme('colors.neutral.200');
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
      }

      @keyframes dot-flashing {
        0% {
          background-color: theme('colors.neutral.200');
        }
        50% {
          background-color: theme('colors.neutral.300');
        }
        100% {
          background-color: theme('colors.neutral.400');
        }
      }
    `,
  ],
  template: `
    <div class="flex gap-1 tablet-landscape:gap-3">
      @if (!isFromUser() && !isLoading()) {
      <div
        class="flex items-center justify-center rounded-full mt-2 size-6 tablet-landscape:size-8 bg-gradient-a-diagonal flex-shrink-0"
      >
        <svg class="text-shades-white size-4 mr-0.5" sdUseAiIcon="stars"></svg>
      </div>
      } @if (isThinking()) {
      <div
        class="flex justify-start items-center relative overflow-hidden tablet-landscape:mt-1 p-4"
      >
        <div class="dot-flashing"></div>
      </div>
      } @else if (message()) {
      <div
        class="rounded-2xl typo-p2"
        [ngClass]="{
          'bg-primary-500/10': isFromUser(),
          'bg-primary-200/20 animate-pulse': isLoading(),
          'p-2': !isFromUser(), 
          'px-4 py-3 max-w-70 tablet-landscape:max-w-110': isFromUser(),
          'p-2 prose': !isFromUser(),
        }"
      >
        @if (isLoading()) {
        <div class="text-transparent">
          {{ message() }}
        </div>
        } @else { @if (isFromUser()) {
        {{ message() }}
        } @else {
        <markdown [data]="message()"></markdown>
        } }
      </div>
      }
    </div>
  `,
})
export class MessageComponent {
  readonly message = input<string>('');
  readonly isFromUser = input<boolean>(false);
  readonly isThinking = input<boolean>(false);
  readonly isLoading = input<boolean>(false);
}
