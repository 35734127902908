export enum UIBasicIconsEnum {
  'plus' = 'plus',
  'search' = 'search',
  'voice' = 'voice',
  'menu' = 'menu',
  'close' = 'close',
  'more-horizontal' = 'more-horizontal',
  'more-vertical' = 'more-vertical',
  'warning-filled' = 'warning-filled',
  'add-document' = 'add-document',
  'add-folder' = 'add-folder',
  'star' = 'star',
  'save' = 'save',
  'edit' = 'edit',
  'archive' = 'archive',
  'delete' = 'delete',
  'clock' = 'clock',
  'info' = 'info',
  'zoom-out' = 'zoom-out',
  'zoom-in' = 'zoom-in',
  'success-filled' = 'success-filled',
  'error-filled' = 'error-filled',
  'bubbles' = 'bubbles',
  'trend-up' = 'trend-up',
  'refresh' = 'refresh',
  'attach' = 'attach',
  'bulb' = 'bulb',
}

export type UIBasicIconsType = `${UIBasicIconsEnum}`;
export const UIBasicIconsList = Object.values(UIBasicIconsEnum);
