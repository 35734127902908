import { Component, input, output } from '@angular/core';

@Component({
  selector: 'sd-drawer',
  standalone: true,
  styles: `
      :host {
        @apply fixed w-full bottom-0 pointer-events-none
      }
  `,
  template: `
    <div
      class="relative w-full h-full transition-opacity duration-150 ease-in-out"
      [class.modal-backdrop]="isOpen()"
      [class.opacity-0]="!isOpen()"
      [class.opacity-100]="isOpen()"
      [class.pointer-events-none]="!isOpen()"
      [class.pointer-events-auto]="isOpen()"
      (click)="closeClicked.emit()"
    >
      <div
        class="absolute inset-0 flex items-end justify-center transition-transform duration-500 ease-in-out overflow-hidden"
        [class.translate-y-full]="!isOpen()"
        [class.translate-y-0]="isOpen()"
      >
        <div
          class="w-full h-fit shadow-lg rounded-t-3xl bg-shades-white flex flex-col"
        >
          @if (hasCloseButton()) {
          <div class="flex h-7 items-center justify-center">
            <div
              class="w-6 h-1 mt-2 bg-neutral-200 rounded-full cursor-pointer"
              (click)="closeClicked.emit()"
            ></div>
          </div>
          }
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
})
export class DrawerComponent {
  public readonly isOpen = input.required<boolean>();
  public readonly hasCloseButton = input<boolean>(true);

  public readonly closeClicked = output();
}
