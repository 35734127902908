import { Directive, computed, input } from '@angular/core';
import { cva, type VariantProps } from 'class-variance-authority';

import * as fromAtomsColors from '../atoms/colors';
import * as fromHelpers from '../helpers';

export enum ButtonVariant {
  filled = 'filled',
  tonal = 'tonal',
  outline = 'outline',
}

export enum ButtonSizeVariant {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
}

export enum ButtonColorVariant {
  primary = fromAtomsColors.ColorVariant.primary,
  neutral = fromAtomsColors.ColorVariant.neutral,
  error = fromAtomsColors.ColorVariant.error,
}

/**
 * Currently supported variants matrix
 *
 * | Type   | Color   | Size | Disabled |
 * |--------|---------|------|----------|
 * | Filled | Primary | xs / sm / md   | True     |
 * | Filled | Primary | xs / sm / md   | False    |
 * | Filled | Neutral | xs / sm / md   | True     |
 * | Filled | Neutral | xs / sm / md   | False    |
 * | Filled | Error   | xs / sm / md   | False    |
 * | Filled | Error   | xs / sm / md   | False    |
 * | Tonal  | Primary | xs / sm / md   | True     |
 * | Tonal  | Primary | xs / sm / md   | False    |
 * | Tonal  | Neutral | xs / sm / md   | True     |
 * | Tonal  | Neutral | xs / sm / md   | False    |
 * | Tonal  | Error   | xs / sm / md   | True     |
 * | Tonal  | Error   | xs / sm / md   | False    |
 * | Outline | Neutral | xs / sm / md   | True     |
 * | Outline | Neutral | xs / sm / md   | False    |
 */
export const buttonVariants = cva(
  fromHelpers.cn(
    'inline-flex items-center justify-center gap-2 pt-0.5',
    'typo-p2 font-medium rounded-3xl transition-colors',
    'disabled:opacity-50 disabled:pointer-events-none',
    'active:scale-[0.995]',
    '[&>svg]:mb-0.5'
  ),
  {
    variants: {
      variant: {
        [`${ButtonVariant.filled}`]: '', //default
        [`${ButtonVariant.tonal}`]: '',
        [`${ButtonVariant.outline}`]: '',
      },
      color: {
        [`${ButtonColorVariant.primary}`]: '', // default
        [`${ButtonColorVariant.neutral}`]: '',
        [`${ButtonColorVariant.error}`]: '',
      },
      size: {
        [`${ButtonSizeVariant.xs}`]:
          'h-8 px-3 has-[svg]:pl-2 [&>svg]:size-4 [&>svg]:min-w-4 pt-0 typo-caption tablet-portrait:text-caption font-semibold',
        [`${ButtonSizeVariant.sm}`]:
          'h-10 px-6 has-[svg]:pl-4 [&>svg]:size-4 [&>svg]:min-w-4 typo-p3 tablet-portrait:text-p3 font-semibold',
        [`${ButtonSizeVariant.md}`]:
          'h-12 px-6 has-[svg]:pl-4 [&>svg]:size-6 [&>svg]:min-w-6', //default
      },
    },
    defaultVariants: {
      variant: ButtonVariant.filled,
      color: ButtonColorVariant.primary,
      size: ButtonSizeVariant.md,
    },
    compoundVariants: [
      {
        variant: ButtonVariant.filled,
        color: ButtonColorVariant.primary,
        class: fromHelpers.cn(
          'bg-primary-500 text-shades-white',
          'hover:bg-primary-400',
          'focus-visible:bg-primary-400',
          'active:bg-primary-500 ring-active:ring-primary-500',
          // eslint-disable-next-line sonarjs/no-duplicate-string
          'disabled:bg-neutral-200 disabled:text-neutral-500'
        ),
      },
      {
        variant: ButtonVariant.filled,
        color: ButtonColorVariant.neutral,
        class: fromHelpers.cn(
          'bg-neutral-700 text-shades-white',
          'hover:bg-neutral-600',
          'focus-visible:bg-neutral-600',
          'active:bg-neutral-700 ring-active:ring-neutral-700',
          'disabled:bg-neutral-200 disabled:text-neutral-500'
        ),
      },
      {
        variant: ButtonVariant.filled,
        color: ButtonColorVariant.error,
        class: fromHelpers.cn(
          'bg-error-500 text-shades-white',
          'hover:bg-error-400',
          'focus-visible:bg-error-400',
          'active:bg-error-500 ring-active:ring-error-500',
          'disabled:bg-neutral-200 disabled:text-neutral-500'
        ),
      },
      {
        variant: ButtonVariant.tonal,
        color: ButtonColorVariant.primary,
        class: fromHelpers.cn(
          'bg-primary-50 text-primary-600',
          'hover:bg-primary-100',
          'focus-visible:bg-primary-100',
          'active:bg-primary-200 ring-active:ring-primary-200',
          'disabled:bg-neutral-200 disabled:text-neutral-500'
        ),
      },
      {
        variant: ButtonVariant.tonal,
        color: ButtonColorVariant.error,
        class: fromHelpers.cn(
          'bg-error-50 text-error-700',
          'hover:bg-error-50',
          'focus-visible:bg-error-50',
          'active:bg-error-200 ring-active:ring-error-200',
          'disabled:bg-neutral-100 disabled:text-neutral-500'
        ),
      },
      {
        variant: ButtonVariant.outline,
        color: ButtonColorVariant.neutral,
        class: fromHelpers.cn(
          'bg-shades-white text-neutral-700',
          'border border-neutral-200',
          'hover:border-neutral-300',
          'focus-visible:border-neutral-300',
          'active:border-neutral-400 ring-active:ring-neutral-400',
          'disabled:border-neutral-200 disabled:text-neutral-400'
        ),
      },
    ],
  }
);
export type ButtonVariants = VariantProps<typeof buttonVariants>;

@Directive({
  selector: '[sdButton]',
  standalone: true,
  host: {
    '[class]': 'className()',
  },
})
export class ButtonDirective {
  readonly variant = input<ButtonVariants['variant']>();
  readonly color = input<ButtonVariants['color']>();
  readonly size = input<ButtonVariants['size']>();

  protected readonly className = computed(() =>
    fromHelpers.cn(
      buttonVariants({
        variant: this.variant(),
        color: this.color(),
        size: this.size(),
      })
    )
  );
}
