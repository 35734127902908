import { InjectionToken } from '@angular/core';
import {
  VoiceToTextApiKeyServiceInterface,
  VoiceToTextServiceInterface,
} from './interfaces';

export const DEEPGRAM_API_KEY_SERVICE =
  new InjectionToken<VoiceToTextApiKeyServiceInterface>(
    'DeepgramApiKeyService'
  );

export const AZURE_API_KEY_SERVICE =
  new InjectionToken<VoiceToTextApiKeyServiceInterface>('AzureApiKeyService');

export const VOICE_TO_TEXT_SERVICE =
  new InjectionToken<VoiceToTextServiceInterface>('VoiceToTextService');

export const VOICE_TO_TEXT_API_KEY_SERVICE =
  new InjectionToken<VoiceToTextApiKeyServiceInterface>(
    'VoiceToTextApiKeyService'
  );
