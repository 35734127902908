export enum ArrowIconsEnum {
  'chevron-small-up' = 'chevron-small-up',
  'chevron-up' = 'chevron-up',
  'chevron-small-right' = 'chevron-small-right',
  'chevron-right' = 'chevron-right',
  'chevron-small-down' = 'chevron-small-down',
  'chevron-down' = 'chevron-down',
  'chevron-small-left' = 'chevron-small-left',
  'chevron-left' = 'chevron-left',
  'arrow-left' = 'arrow-left',
  'arrow-down' = 'arrow-down',
  'arrow-up' = 'arrow-up',
  'arrow-right' = 'arrow-right',
}

export type ArrowIconsType = `${ArrowIconsEnum}`;
export const ArrowIconsList = Object.values(ArrowIconsEnum);
