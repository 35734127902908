import { Directive, input } from '@angular/core';

import { BaseIconDirective } from './base-icon.directive';
import { LoginIconsType } from './login';

@Directive({
  selector: 'svg[sdUseLoginIcon]',
  standalone: true,
})
export class UseLoginIconDirective extends BaseIconDirective {
  public readonly sdUseLoginIcon = input.required<LoginIconsType>();
  readonly BASE_PATH = '/assets/login.sprites.svg';

  public get icon() {
    return this.sdUseLoginIcon();
  }
}
