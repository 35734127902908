import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import {
  FileTypesIconsType,
  SpinnerDirective,
  UseFileTypeIconDirective,
  UseUIBasicIconDirective,
} from '../atoms';

@Component({
  selector: 'sd-file-chip',
  imports: [
    UseUIBasicIconDirective,
    UseFileTypeIconDirective,
    NgClass,
    SpinnerDirective,
  ],
  template: `
    <div
      class="
        group
        flex items-center justify-center gap-2
        rounded-full border border-neutral-200 hover:border-neutral-300 active:border-neutral-400
        h-10 pl-3 pr-4 py-2
        w-fit
        max-w-60
        z-20
      "
    >
      @if (isLoading()) {
      <span class="flex-shrink-0" sdSpinner></span>
      } @else {
      <svg [sdUseFileTypeIcon]="fileType()" class="size-5 flex-shrink-0"></svg>
      }
      <span
        class="
        typo-p3 text-neutral-700
        truncate
        disabled:text-neutral-400 disabled:cursor-not-allowed disabled:active:border-neutral-200
        "
        >{{ title() }}</span
      >
      <svg
        sdUseUIBasicIcon="close"
        class="visible size-5 flex-shrink-0"
        [ngClass]="{
          'cursor-not-allowed': isDisabled(),
          'cursor-pointer': !isDisabled(),
        }"
        (click)="deleteClicked.emit(title())"
      ></svg>
    </div>
  `,
})
export class FileChipComponent {
  public readonly title = input.required<string>();
  public readonly fileType = input<FileTypesIconsType>('pdf');
  public readonly isDisabled = input<boolean>(false);
  public readonly isLoading = input<boolean>(false);

  public deleteClicked = output<string>();
}
