import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Renderer2,
  inject,
  input,
} from '@angular/core';

import { LogoType } from './logos';

@Directive({
  selector: 'img[sdUseLogo]',
  standalone: true,
})
export class UseLogoDirective implements AfterViewInit {
  readonly elementRef = inject<ElementRef<SVGElement>>(ElementRef);
  readonly renderer = inject(Renderer2);
  readonly document = inject<Document>(DOCUMENT);

  readonly sdUseLogo = input.required<LogoType>();

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.setAttribute(
      'src',
      `/assets/logos/${this.sdUseLogo()}.svg`
    );
  }
}
