export enum FileTypeIconsEnum {
  'pdf' = 'pdf',
  'default' = 'default',
  'excel' = 'excel',
  'word' = 'word',
  'powerpoint' = 'powerpoint',
  'image' = 'image',
  'csv' = 'csv',
  'folder' = 'folder',
}

export type FileTypesIconsType = `${FileTypeIconsEnum}`;
export const FileTypesIconsList = Object.values(FileTypeIconsEnum);
