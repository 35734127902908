import { Directive, input } from '@angular/core';

import { BaseIconDirective } from './base-icon.directive';
import { SocialIconsType } from './socials';

@Directive({
  selector: 'svg[sdUseSocialIcon]',
  standalone: true,
})
export class UseSocialIconDirective extends BaseIconDirective {
  public readonly sdUseSocialIcon = input.required<SocialIconsType>();
  readonly BASE_PATH = '/assets/socials.sprites.svg';

  public get icon() {
    return this.sdUseSocialIcon();
  }
}
