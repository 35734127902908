import { Directive, input } from '@angular/core';

import { BaseIconDirective } from './base-icon.directive';
import { FileTypesIconsType } from './file-types';

@Directive({
  selector: 'svg[sdUseFileTypeIcon]',
  standalone: true,
})
export class UseFileTypeIconDirective extends BaseIconDirective {
  public readonly sdUseFileTypeIcon = input.required<FileTypesIconsType>();
  readonly BASE_PATH = '/assets/file-types.sprites.svg';

  public get icon() {
    return this.sdUseFileTypeIcon();
  }
}
