import { Directive, computed } from '@angular/core';
import { cva } from 'class-variance-authority';
import * as fromHelpers from '../../helpers';

export const spinnerVariants = cva(
  fromHelpers.cn(
    'inline-block rounded-full',
    'border-[3px]',
    'size-4',
    'animate-spin',
    'border-t-primary-400',
    'border-r-primary-400/85',
    'border-b-primary-400/75',
    'border-l-primary-500/10'
  )
);

@Directive({
  selector: '[sdSpinner]',
  standalone: true,
  host: {
    '[class]': 'className()',
    role: 'status',
  },
})
export class SpinnerDirective {
  protected readonly className = computed(() =>
    fromHelpers.cn(spinnerVariants())
  );
}
