import { Directive, input } from '@angular/core';

import { AiIconsType } from './ais';
import { BaseIconDirective } from './base-icon.directive';

@Directive({
  selector: 'svg[sdUseAiIcon]',
  standalone: true,
})
export class UseAiIconDirective extends BaseIconDirective {
  public readonly sdUseAiIcon = input.required<AiIconsType>();
  readonly BASE_PATH = '/assets/ais.sprites.svg';

  public get icon() {
    return this.sdUseAiIcon();
  }
}
