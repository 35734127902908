import {
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import * as fromSeriousSystem from '../atoms';
import { OptionMenu, OptionMenuComponent } from './option-menu.component';

@Component({
  selector: 'sd-dropdown-menu',
  imports: [fromSeriousSystem.UseUIBasicIconDirective, OptionMenuComponent],
  template: `
    <div
      class="flex flex-col w-fit bg-shades-white shadow-md rounded-md border border-neutral-200"
      [class.hidden]="!isDropdownVisible()"
      [class.visible]="isDropdownVisible()"
    >
      @for (option of options(); track option.label) {
      <sd-option-menu
        [option]="option"
        (triggerAction)="onTriggerAction($event)"
      ></sd-option-menu>
      }
    </div>
  `,
  styles: [``],
})
export class DropdownMenuComponent implements OnDestroy {
  public readonly options = input.required<OptionMenu[]>();
  public readonly isDropdownMenuOpened = input.required<boolean>();
  public readonly triggerAction = output<string>();

  public isDropdownVisible = signal(false);
  private clickListener: () => void;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {
    this.clickListener = this.renderer.listen(
      'document',
      'click',
      this.handleClickOutside.bind(this)
    );

    effect(
      () => {
        this.isDropdownVisible.set(this.isDropdownMenuOpened());
      },
      { allowSignalWrites: true }
    );
  }

  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
    }
  }

  private handleClickOutside(event: Event) {
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      !this.elRef.nativeElement.contains(event.target) &&
      this.isDropdownMenuOpened()
    ) {
      this.isDropdownVisible.set(false);
      this.triggerAction.emit('outside-click');
    }
  }

  public onTriggerAction(action: string) {
    this.triggerAction.emit(action);
  }
}
