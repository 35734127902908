import { Directive, input } from '@angular/core';

import { BaseIconDirective } from './base-icon.directive';
import { UIBasicIconsType } from './ui-basics';

@Directive({
  selector: 'svg[sdUseUIBasicIcon]',
  standalone: true,
})
export class UseUIBasicIconDirective extends BaseIconDirective {
  public readonly sdUseUIBasicIcon = input.required<UIBasicIconsType>();
  readonly BASE_PATH = '/assets/ui-basics.sprites.svg';

  public get icon() {
    return this.sdUseUIBasicIcon();
  }
}
