import { Directive, input } from '@angular/core';

import { BaseIconDirective } from './base-icon.directive';
import { NavigationIconsType } from './navigations';

@Directive({
  selector: 'svg[sdUseNavigationIcon]',
  standalone: true,
})
export class UseNavigationIconDirective extends BaseIconDirective {
  public readonly sdUseNavigationIcon = input.required<NavigationIconsType>();
  readonly BASE_PATH = '/assets/navigations.sprites.svg';

  public get icon() {
    return this.sdUseNavigationIcon();
  }
}
