export enum IllustrationEnum {
  'bubble' = 'bubble',
  'convention' = 'convention',
  'files' = 'files',
  'folder' = 'folder',
  'knowledge' = 'knowledge',
  'weather-afternoon' = 'weather-afternoon',
  'weather-earlymorning' = 'weather-earlymorning',
  'weather-evening' = 'weather-evening',
  'weather-morning' = 'weather-morning',
  'weather-night' = 'weather-night',
}

export type IllustrationType = `${IllustrationEnum}`;
export const IllustrationList = Object.keys(
  IllustrationEnum
) as IllustrationEnum[];
