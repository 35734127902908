import { Directive, computed, input } from '@angular/core';
import { cva, type VariantProps } from 'class-variance-authority';

import * as fromAtomsColors from '../atoms/colors';
import * as fromHelpers from '../helpers';

export enum IconButtonVariant {
  outlined = 'outlined',
  icon = 'icon',
  filled = 'filled',
}

export enum IconButtonColorVariant {
  primary = fromAtomsColors.ColorVariant.primary,
  neutral = fromAtomsColors.ColorVariant.neutral,
}

export enum IconButtonSizeVariant {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export const iconButtonVariants = cva(
  fromHelpers.cn(
    'flex items-center justify-center rounded-full hover:cursor-pointer disabled:cursor-not-allowed'
  ),
  {
    variants: {
      variant: {
        [`${IconButtonVariant.outlined}`]: `
          border border-neutral-200
          hover:border-neutral-300
          focus:border-neutral-300
          active:border-neutral-400
          disabled:border-neutral-200 disabled:text-neutral-400`,
        [`${IconButtonVariant.icon}`]: 'disabled:hover:bg-transparent',
        [`${IconButtonVariant.filled}`]: '',
      },
      color: {
        [`${IconButtonColorVariant.primary}`]: '',
        [`${IconButtonColorVariant.neutral}`]: '',
      },
      size: {
        [`${IconButtonSizeVariant.xs}`]: 'w-8 h-8 [&>svg]:size-5',
        [`${IconButtonSizeVariant.sm}`]: 'w-10 h-10 [&>svg]:size-6',
        [`${IconButtonSizeVariant.md}`]: 'w-12 h-12 [&>svg]:size-6',
        [`${IconButtonSizeVariant.lg}`]: 'w-14 h-14 [&>svg]:size-8',
      },
    },
    defaultVariants: {
      variant: IconButtonVariant.outlined,
      color: IconButtonColorVariant.primary,
      size: IconButtonSizeVariant.md,
    },
    compoundVariants: [
      {
        variant: IconButtonVariant.outlined,
        color: IconButtonColorVariant.primary,
        class: fromHelpers.cn('text-primary-600'),
      },
      {
        variant: IconButtonVariant.outlined,
        color: IconButtonColorVariant.neutral,
        class: fromHelpers.cn('text-neutral-700'),
      },
      {
        variant: IconButtonVariant.icon,
        color: IconButtonColorVariant.primary,
        class: fromHelpers.cn(
          'text-primary-600',
          'hover:bg-primary-500/5',
          'focus:bg-primary-500/5',
          'active:bg-primary-500/10',
          'disabled:text-neutral-400'
        ),
      },
      {
        variant: IconButtonVariant.icon,
        color: IconButtonColorVariant.neutral,
        class: fromHelpers.cn(
          'text-neutral-700 dark:text-neutral-50',
          'hover:bg-transparency-black-4 dark:hover:bg-transparency-white-4',
          'focus:bg-transparency-black-4 dark:hover:bg-transparency-white-4',
          'active:bg-transparency-black-8 dark:active:bg-transparency-white-8',
          'disabled:text-neutral-400'
        ),
      },
      {
        variant: IconButtonVariant.filled,
        color: IconButtonColorVariant.primary,
        class: fromHelpers.cn(
          'bg-primary-500',
          'disabled:text-neutral-500',
          'text-shades-white',
          'hover:bg-primary-400',
          'focus:bg-primary-400',
          'active:bg-primary-500',
          'disabled:bg-neutral-200'
        ),
      },
      {
        variant: IconButtonVariant.filled,
        color: IconButtonColorVariant.neutral,
        class: fromHelpers.cn(
          'bg-neutral-700',
          'disabled:text-neutral-500',
          'text-shades-white',
          'hover:bg-neutral-600',
          'focus:bg-neutral-600',
          'active:bg-neutral-700',
          'disabled:bg-neutral-200'
        ),
      },
    ],
  }
);
export type IconButtonVariants = VariantProps<typeof iconButtonVariants>;

@Directive({
  selector: '[sdIconButton]',
  standalone: true,
  host: {
    '[class]': 'className()',
  },
})
export class IconButtonDirective {
  readonly variant = input<IconButtonVariants['variant']>(
    IconButtonVariant.outlined
  );
  readonly color = input<IconButtonVariants['color']>(
    IconButtonColorVariant.primary
  );
  readonly size = input<IconButtonVariants['size']>(IconButtonSizeVariant.md);

  protected readonly className = computed(() =>
    fromHelpers.cn(
      iconButtonVariants({
        variant: this.variant(),
        color: this.color(),
        size: this.size(),
      })
    )
  );
}
