export enum HeroIconsEnum {
  'academic-cap' = 'academic-cap',
  'battery-100' = 'battery-100',
  'cog-6-tooth' = 'cog-6-tooth',
  'hand-raised' = 'hand-raised',
  'queue-list' = 'queue-list',
  'adjustments-horizontal' = 'adjustments-horizontal',
  'battery-50' = 'battery-50',
  'cog-8-tooth' = 'cog-8-tooth',
  'hand-thumb-down' = 'hand-thumb-down',
  'radio' = 'radio',
  'adjustments-vertical' = 'adjustments-vertical',
  'beaker' = 'beaker',
  'command-line' = 'command-line',
  'hand-thumb-up' = 'hand-thumb-up',
  'receipt-percent' = 'receipt-percent',
  'archive-box' = 'archive-box',
  'bell' = 'bell',
  'computer-desktop' = 'computer-desktop',
  'hashtag' = 'hashtag',
  'receipt-refund' = 'receipt-refund',
  'archive-box-arrow-down' = 'archive-box-arrow-down',
  'bell-alert' = 'bell-alert',
  'cpu-chip' = 'cpu-chip',
  'heart' = 'heart',
  'rectangle-group' = 'rectangle-group',
  'archive-box-x-mark' = 'archive-box-x-mark',
  'bell-slash' = 'bell-slash',
  'credit-card' = 'credit-card',
  'home' = 'home',
  'rectangle-stack' = 'rectangle-stack',
  'arrow-down' = 'arrow-down',
  'bell-snooze' = 'bell-snooze',
  'cube' = 'cube',
  'home-modern' = 'home-modern',
  'rocket-launch' = 'rocket-launch',
  'arrow-down-circle' = 'arrow-down-circle',
  'bolt' = 'bolt',
  'cube-transparent' = 'cube-transparent',
  'identification' = 'identification',
  'rss' = 'rss',
  'arrow-down-left' = 'arrow-down-left',
  'bolt-slash' = 'bolt-slash',
  'currency-bangladeshi' = 'currency-bangladeshi',
  'inbox' = 'inbox',
  'scale' = 'scale',
  'arrow-down-on-square' = 'arrow-down-on-square',
  'book-open' = 'book-open',
  'currency-dollar' = 'currency-dollar',
  'inbox-arrow-down' = 'inbox-arrow-down',
  'scissors' = 'scissors',
  'arrow-down-on-square-stack' = 'arrow-down-on-square-stack',
  'bookmark' = 'bookmark',
  'currency-euro' = 'currency-euro',
  'inbox-stack' = 'inbox-stack',
  'server' = 'server',
  'arrow-down-right' = 'arrow-down-right',
  'bookmark-slash' = 'bookmark-slash',
  'currency-pound' = 'currency-pound',
  'information-circle' = 'information-circle',
  'server-stack' = 'server-stack',
  'arrow-down-tray' = 'arrow-down-tray',
  'bookmark-square' = 'bookmark-square',
  'currency-rupee' = 'currency-rupee',
  'key' = 'key',
  'share' = 'share',
  'arrow-left' = 'arrow-left',
  'briefcase' = 'briefcase',
  'currency-yen' = 'currency-yen',
  'language' = 'language',
  'shield-check' = 'shield-check',
  'arrow-left-circle' = 'arrow-left-circle',
  'bug-ant' = 'bug-ant',
  'cursor-arrow-rays' = 'cursor-arrow-rays',
  'lifebuoy' = 'lifebuoy',
  'shield-exclamation' = 'shield-exclamation',
  'arrow-left-end-on-rectangle' = 'arrow-left-end-on-rectangle',
  'building-library' = 'building-library',
  'cursor-arrow-ripple' = 'cursor-arrow-ripple',
  'light-bulb' = 'light-bulb',
  'shopping-bag' = 'shopping-bag',
  'arrow-left-start-on-rectangle' = 'arrow-left-start-on-rectangle',
  'building-office' = 'building-office',
  'device-phone-mobile' = 'device-phone-mobile',
  'link' = 'link',
  'shopping-cart' = 'shopping-cart',
  'arrow-long-down' = 'arrow-long-down',
  'building-office-2' = 'building-office-2',
  'device-tablet' = 'device-tablet',
  'list-bullet' = 'list-bullet',
  'signal' = 'signal',
  'arrow-long-left' = 'arrow-long-left',
  'building-storefront' = 'building-storefront',
  'document' = 'document',
  'lock-closed' = 'lock-closed',
  'signal-slash' = 'signal-slash',
  'arrow-long-right' = 'arrow-long-right',
  'cake' = 'cake',
  'document-arrow-down' = 'document-arrow-down',
  'lock-open' = 'lock-open',
  'sparkles' = 'sparkles',
  'arrow-long-up' = 'arrow-long-up',
  'calculator' = 'calculator',
  'document-arrow-up' = 'document-arrow-up',
  'magnifying-glass' = 'magnifying-glass',
  'speaker-wave' = 'speaker-wave',
  'arrow-path' = 'arrow-path',
  'calendar' = 'calendar',
  'document-chart-bar' = 'document-chart-bar',
  'magnifying-glass-circle' = 'magnifying-glass-circle',
  'speaker-x-mark' = 'speaker-x-mark',
  'arrow-path-rounded-square' = 'arrow-path-rounded-square',
  'calendar-days' = 'calendar-days',
  'document-check' = 'document-check',
  'magnifying-glass-minus' = 'magnifying-glass-minus',
  'square-2-stack' = 'square-2-stack',
  'arrow-right' = 'arrow-right',
  'camera' = 'camera',
  'document-duplicate' = 'document-duplicate',
  'magnifying-glass-plus' = 'magnifying-glass-plus',
  'squares-2x2' = 'squares-2x2',
  'arrow-right-circle' = 'arrow-right-circle',
  'chart-bar' = 'chart-bar',
  'document-magnifying-glass' = 'document-magnifying-glass',
  'map' = 'map',
  'squares-plus' = 'squares-plus',
  'arrow-right-end-on-rectangle' = 'arrow-right-end-on-rectangle',
  'chart-bar-square' = 'chart-bar-square',
  'document-minus' = 'document-minus',
  'map-pin' = 'map-pin',
  'square-3-stack-3d' = 'square-3-stack-3d',
  'arrow-right-start-on-rectangle' = 'arrow-right-start-on-rectangle',
  'chart-pie' = 'chart-pie',
  'document-plus' = 'document-plus',
  'megaphone' = 'megaphone',
  'star' = 'star',
  'arrow-top-right-on-square' = 'arrow-top-right-on-square',
  'chat-bubble-bottom-center' = 'chat-bubble-bottom-center',
  'document-text' = 'document-text',
  'microphone' = 'microphone',
  'stop' = 'stop',
  'arrow-trending-down' = 'arrow-trending-down',
  'chat-bubble-bottom-center-text' = 'chat-bubble-bottom-center-text',
  'ellipsis-horizontal' = 'ellipsis-horizontal',
  'minus' = 'minus',
  'stop-circle' = 'stop-circle',
  'arrow-trending-up' = 'arrow-trending-up',
  'chat-bubble-left' = 'chat-bubble-left',
  'ellipsis-horizontal-circle' = 'ellipsis-horizontal-circle',
  'minus-circle' = 'minus-circle',
  'sun' = 'sun',
  'arrow-up' = 'arrow-up',
  'chat-bubble-left-ellipsis' = 'chat-bubble-left-ellipsis',
  'ellipsis-vertical' = 'ellipsis-vertical',
  'moon' = 'moon',
  'swatch' = 'swatch',
  'arrow-up-circle' = 'arrow-up-circle',
  'chat-bubble-left-right' = 'chat-bubble-left-right',
  'envelope' = 'envelope',
  'musical-note' = 'musical-note',
  'table-cells' = 'table-cells',
  'arrow-up-left' = 'arrow-up-left',
  'chat-bubble-oval-left' = 'chat-bubble-oval-left',
  'envelope-open' = 'envelope-open',
  'newspaper' = 'newspaper',
  'tag' = 'tag',
  'arrow-up-on-square' = 'arrow-up-on-square',
  'chat-bubble-oval-left-ellipsis' = 'chat-bubble-oval-left-ellipsis',
  'exclamation-circle' = 'exclamation-circle',
  'no-symbol' = 'no-symbol',
  'ticket' = 'ticket',
  'arrow-up-on-square-stack' = 'arrow-up-on-square-stack',
  'check' = 'check',
  'exclamation-triangle' = 'exclamation-triangle',
  'paint-brush' = 'paint-brush',
  'trash' = 'trash',
  'arrow-up-right' = 'arrow-up-right',
  'check-badge' = 'check-badge',
  'eye' = 'eye',
  'paper-airplane' = 'paper-airplane',
  'trophy' = 'trophy',
  'arrow-up-tray' = 'arrow-up-tray',
  'check-circle' = 'check-circle',
  'eye-dropper' = 'eye-dropper',
  'paper-clip' = 'paper-clip',
  'truck' = 'truck',
  'arrow-uturn-down' = 'arrow-uturn-down',
  'chevron-double-down' = 'chevron-double-down',
  'eye-slash' = 'eye-slash',
  'pause' = 'pause',
  'tv' = 'tv',
  'arrow-uturn-left' = 'arrow-uturn-left',
  'chevron-double-left' = 'chevron-double-left',
  'face-frown' = 'face-frown',
  'pause-circle' = 'pause-circle',
  'user' = 'user',
  'arrow-uturn-right' = 'arrow-uturn-right',
  'chevron-double-right' = 'chevron-double-right',
  'face-smile' = 'face-smile',
  'pencil' = 'pencil',
  'user-circle' = 'user-circle',
  'arrow-uturn-up' = 'arrow-uturn-up',
  'chevron-double-up' = 'chevron-double-up',
  'film' = 'film',
  'pencil-square' = 'pencil-square',
  'user-group' = 'user-group',
  'arrows-pointing-in' = 'arrows-pointing-in',
  'chevron-down' = 'chevron-down',
  'finger-print' = 'finger-print',
  'phone' = 'phone',
  'user-minus' = 'user-minus',
  'arrows-pointing-out' = 'arrows-pointing-out',
  'chevron-left' = 'chevron-left',
  'fire' = 'fire',
  'phone-arrow-down-left' = 'phone-arrow-down-left',
  'user-plus' = 'user-plus',
  'arrows-right-left' = 'arrows-right-left',
  'chevron-right' = 'chevron-right',
  'flag' = 'flag',
  'phone-arrow-up-right' = 'phone-arrow-up-right',
  'users' = 'users',
  'arrows-up-down' = 'arrows-up-down',
  'chevron-up' = 'chevron-up',
  'folder' = 'folder',
  'phone-x-mark' = 'phone-x-mark',
  'variable' = 'variable',
  'at-symbol' = 'at-symbol',
  'chevron-up-down' = 'chevron-up-down',
  'folder-arrow-down' = 'folder-arrow-down',
  'photo' = 'photo',
  'video-camera' = 'video-camera',
  'backspace' = 'backspace',
  'circle-stack' = 'circle-stack',
  'folder-minus' = 'folder-minus',
  'play' = 'play',
  'video-camera-slash' = 'video-camera-slash',
  'backward' = 'backward',
  'clipboard' = 'clipboard',
  'folder-open' = 'folder-open',
  'play-circle' = 'play-circle',
  'view-columns' = 'view-columns',
  'banknotes' = 'banknotes',
  'clipboard-document' = 'clipboard-document',
  'folder-plus' = 'folder-plus',
  'play-pause' = 'play-pause',
  'viewfinder-circle' = 'viewfinder-circle',
  'bars-2' = 'bars-2',
  'clipboard-document-check' = 'clipboard-document-check',
  'forward' = 'forward',
  'plus' = 'plus',
  'wallet' = 'wallet',
  'bars-3' = 'bars-3',
  'clipboard-document-list' = 'clipboard-document-list',
  'funnel' = 'funnel',
  'plus-circle' = 'plus-circle',
  'wifi' = 'wifi',
  'bars-3-bottom-left' = 'bars-3-bottom-left',
  'clock' = 'clock',
  'gif' = 'gif',
  'power' = 'power',
  'window' = 'window',
  'bars-3-bottom-right' = 'bars-3-bottom-right',
  'cloud' = 'cloud',
  'gift' = 'gift',
  'presentation-chart-bar' = 'presentation-chart-bar',
  'wrench' = 'wrench',
  'bars-3-center-left' = 'bars-3-center-left',
  'cloud-arrow-down' = 'cloud-arrow-down',
  'gift-top' = 'gift-top',
  'presentation-chart-line' = 'presentation-chart-line',
  'wrench-screwdriver' = 'wrench-screwdriver',
  'bars-4' = 'bars-4',
  'cloud-arrow-up' = 'cloud-arrow-up',
  'globe-alt' = 'globe-alt',
  'printer' = 'printer',
  'x-circle' = 'x-circle',
  'bars-arrow-down' = 'bars-arrow-down',
  'code-bracket' = 'code-bracket',
  'globe-americas' = 'globe-americas',
  'puzzle-piece' = 'puzzle-piece',
  'x-mark' = 'x-mark',
  'bars-arrow-up' = 'bars-arrow-up',
  'code-bracket-square' = 'code-bracket-square',
  'globe-asia-australia' = 'globe-asia-australia',
  'qr-code' = 'qr-code',
  'battery-0' = 'battery-0',
  'cog' = 'cog',
  'globe-europe-africa' = 'globe-europe-africa',
  'question-mark-circle' = 'question-mark-circle',
}

export type HeroIconsType = `${HeroIconsEnum}`;
export const HeroIconsList = Object.values(HeroIconsEnum);
