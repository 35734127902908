import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Renderer2,
  inject,
} from '@angular/core';

@Directive()
export abstract class BaseIconDirective implements AfterViewInit {
  protected readonly elementRef = inject<ElementRef<SVGElement>>(ElementRef);
  protected readonly renderer = inject(Renderer2);
  protected readonly document = inject<Document>(DOCUMENT);

  ngAfterViewInit(): void {
    const useElement = this.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'use'
    );

    this.elementRef.nativeElement.setAttribute('width', '100%');
    this.elementRef.nativeElement.setAttribute('height', '100%');

    useElement.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'href',
      `${this.BASE_PATH}#${this.icon}`
    );

    this.renderer.appendChild(this.elementRef.nativeElement, useElement);
  }

  protected abstract BASE_PATH: string;
  protected abstract icon: string;
}
